:root {
  --color-1: rgb(13,0,0);
  --color-2: rgb(38,1,1);
  --color-3: rgb(115,65,61);
  --color-4: 242,96,82;
  --color-5: 242,120,92;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 28px;
  color: var(--color-1);
  background-image: url("red-background-material.jpg");
  background-size: auto;
  background-repeat: repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: rgba(var(--color-4), 0.5);
  border-bottom: 1px solid var(--color-2);
}

main {
  max-width: 1200px;
  margin: auto;
  background-color: white;
  background-image: url("stopwatch-svgrepo-com.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

footer {
  background: black;
  color: white;
  width: 100%;
  flex-grow: 1;
  padding: 2em;
  box-sizing: border-box;
  font-size: 0.5em;
  border-top: 2px solid white;
}

h1 {
  text-align: center;
}

.details {
  text-align: center;
  font-size: 0.8em;
  padding: 1em;
}

.distance.custom {
  display: flex;
  font-size: 1.2em;
}

.distance-list {
  margin-top: 1em;
}

.distance-list > div {
  display: flex;
  padding-top: 18px;
  padding-bottom: 16px;
}

.distance, .time {
  flex-basis: 600px;
  flex-shrink: 1;
}

.time input {
  width: 40px;
}

.distance input {
  width: 90px;
  height: 2rem;
}

input {
  font-size: inherit;
  border: none;
  height: 1.5rem;
  text-align: right;
  background-color: inherit;
  color: inherit;
  cursor: pointer;
}

input:focus, input:focus-visible, input:active {
  /*border: none;*/
  outline: none;
  font-style: italic;
  color: var(--color-2);
  font-size: 1.2em;
}

.odd {
  background-color: rgba(var(--color-4), 0.5);
}

.odd:hover {
  background-color: rgba(var(--color-4), 1);
}

.even {
  background-color: rgba(var(--color-5), 0.5);
}

.even:hover {
  background-color: rgba(var(--color-5), 1);
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 500px){
  body {
    font-size: 15px;
  }
  .time input {
    width: 20px;
  }
  .distance input {
    width: 45px;
  }

  .distance input[name="yards"] {
    width: 47px;
  }

}